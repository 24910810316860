function initVideos() {
    $('html').on('click', 'video', function () {
        if (this.paused) {
            this.play();
        } else {
            this.pause();
        }
    });
}
function initAnalytics() {
    if (window.analytics) $('html').on('click', '.analytics-track', function () {
        if (window.analytics) {
            var event = $(this).closest('[data-analytics-track-event]').data('analyticsTrackEvent');
            var location = $(this).closest('[data-analytics-track-location]').data('analyticsTrackLocation');
            
            var properties = {};
            properties.label = $.trim($(this).text());
            if (this.href) { properties.target = this.href; }
            if (location)  { properties.location = location; }
            
            window.analytics.track(event, properties);
        }
    });
}
function initTypeform() {
    $('html').on('click', '.custom-buuuk-typeform-popup-trigger', function (e) {
        if (window.typeformEmbed && window.typeformEmbed.makePopup) {
            window.typeformEmbed.makePopup(this.href, {
                mode: 'popup',
                autoOpen: true,
            });
            e.preventDefault();
        }
    });
}
function initFooterNav() {
    var $footerNav, $page;
    function cacheSelectors() {
        // Figure out if current cached element is in the DOM
        if (!$footerNav ||
            !(
                (
                    document.documentElement.contains &&
                    document.documentElement.contains($footerNav[0])
                ) ||
                $.contains(document.documentElement, $footerNav[0])
            )
        ) {
            $footerNav = $('.footer-nav');
        }
        
        // Figure out if current cached element is in the DOM
        if (!$page ||
            !(
                (
                    document.documentElement.contains &&
                    document.documentElement.contains($page[0])
                ) ||
                $.contains(document.documentElement, $page[0])
            )
        ) {
            $page = $('#page');
        }
    }
    $(window)
        .on('footerNav:updateShadow', function () {
            // Only proceed if footer nav exists
            cacheSelectors();
            if ($footerNav.length === 0) return;
            
            var height = $footerNav[0].outerHeight ?
                    $footerNav[0].outerHeight :
                    $footerNav.outerHeight() ;
            var viewportHeight = document.documentElement.clientHeight ?
                    document.documentElement.clientHeight :
                    $(window).height() ;
            if (viewportHeight > height + 100) {
                $footerNav.addClass('shadowed');
                $page.parent().css('paddingBottom', height + 'px');
            } else {
                $footerNav.removeClass('shadowed');
                $page.parent().css('paddingBottom', '');
            }
        })
        .on('resize load ready', function () {
            $(window)
                .trigger('footerNav:updateShadow')
                .delay(400)
                .trigger('footerNav:updateShadow');
        });
}


function initMobileNav() {
    var header_selector = '.page-header';
    
    $(document).on('click','.nav-toggle', function (e) {
        var ACTIVE_CLASS = 'open';
        var $trigger = $(this);
        var $target = $($trigger.data('toggle'));
        var $header = $(header_selector);
        if ($target.is(':visible')) {
            $target.slideUp(function () {
              $header.removeClass(ACTIVE_CLASS);
            });
            $trigger.removeClass(ACTIVE_CLASS);
        } else {
            $trigger.addClass(ACTIVE_CLASS);
            $header.addClass(ACTIVE_CLASS);
            $target.slideDown();
        }
        e.preventDefault();
    });
}
function initFixedHeaderNav() {
    var header_selector = '.page-header';
    var rubber_band_selector = 'body';
    var $window = $(window);
    var stickyClass = 'sticky';
    var trigger;
    var last_position = null;
    var current_position = null;
    
    function calculateTrigger() {
        trigger = Math.ceil( $(header_selector).height() );
    }
    calculateTrigger();
    $window.on('load ready resize', debounce(calculateTrigger, 200));
    
    $window.on('load ready resize scroll', debounce(function () {
        current_position = $window.scrollTop();
        
        // only execute if current position's status compared to
        // trigger has changed from the last position's status compared to trigger
        if (last_position === null || (last_position < trigger !== current_position < trigger)) {
            if (current_position > trigger) {
                $(header_selector).addClass(stickyClass);
                $(rubber_band_selector).addClass(stickyClass);
            } else {
                $(header_selector).removeClass(stickyClass);
                $(rubber_band_selector).removeClass(stickyClass);
            }
        }
        
        last_position = current_position;
    }, 10));
}

// Init Lightboxes
// uses: https://github.com/electerious/basicLightbox
function initLightboxes() {
    'use strict';
    
    enableFormButtons();
    var dialogs = document.querySelectorAll('[data-lightbox-show]');
    dialogs.forEach(function (trigger) {
        var source = document.querySelector(trigger.dataset.lightboxShow);
        if (source) {
            var lightbox = basicLightbox.create(source, {
                onClose: bodyScrollLock.clearAllBodyScrollLocks,
                className: 'container-gutters',
            });
            var closeTriggers = lightbox.element().querySelectorAll('[data-lightbox-close]');
            trigger.onclick = function (e) {
                e.preventDefault();
                lightbox.show(function (instance) {
                  bodyScrollLock.disableBodyScroll(instance.element());
                  initHubspotEmbeds(instance.element());
                  initHubspotForms();
                  var formPhone = instance.element().querySelector('[data-intl-tel-input]');
                  if (formPhone) {
                    formPhone.addEventListener("open:countrydropdown", function () {
                      bodyScrollLock.disableBodyScroll(document.querySelector('.iti__country-list'));
                    });
                  }
                });
            };
            closeTriggers.forEach(function (close) {
                close.onclick = lightbox.close;
            });
            
            cacheLighboxForms(lightbox, trigger);
        }
    });
    
    // clear all scroll locks
    bodyScrollLock.clearAllBodyScrollLocks();
}

function cacheLighboxForms(lightbox, trigger) {
    var el = lightbox.element();
    var originalTriggerOnClick = trigger.onclick;
    
    // abort if lightbox does not have a hubspot form
    if (!el.querySelector('div[data-hubspot-embed]')) {
        return;
    }
    
    // add lightbox to DOM, hide it, intercept lightbox trigger
    function shadowLightbox() {
        el.classList.add('visuallyhidden', 'layer-hidden');
        document.body.appendChild(el);
        trigger.onclick = function (event) {
            unshadowLightbox();
            originalTriggerOnClick(event);
        }
    }
    
    // once done, remove from DOM, unhide it, restore lightbox trigger
    function unshadowLightbox() {
        el.classList.remove('visuallyhidden', 'layer-hidden');
        el.parentElement.removeChild(el);
        trigger.onclick = originalTriggerOnClick;
    }
    
    // go
    shadowLightbox();
    initHubspotEmbeds(el, unshadowLightbox);
}

//Load Hubspot form script if not present
var hubspotScriptPromise = null;
function loadHubspotFormScript() {
    if (!hubspotScriptPromise) {
        hubspotScriptPromise = new Promise((resolve) => {
            var embedFormScript = '//js.hsforms.net/forms/v2.js';
            loadJS(embedFormScript, resolve);
        });
    }
    return hubspotScriptPromise;
}

// Init native hubspot embeds
function initHubspotEmbeds(containerEl, onFormReadyCallback) {
    'use strict';
    
    containerEl = containerEl || document;
    var embedsSelector = 'div[data-hubspot-embed]';
    var embeds = containerEl.querySelectorAll(embedsSelector);
    
    function embedForms() {
        embeds.forEach(function (embed) {
            // only proceed if the form hasn't already been queued for embed
            if (!embed.dataset.hubspotEmbed) return;
            var options = JSON.parse(embed.dataset.hubspotEmbed);
            if (typeof onFormReadyCallback === 'function') {
              options.onFormReady = onFormReadyCallback;
            }
            options.onFormReady = updateHubspotEmbedSelect;
            hbspt.forms.create(options);
            embed.removeAttribute('data-hubspot-embed');
        });
    }
    
    if (embeds.length) {
        // Load HubSpot forms script first before embeding forms
        loadHubspotFormScript().then(embedForms);
    }
}

// Update Hubspot Embed forms that contain a dropdown with option Other
function updateHubspotEmbedSelect() {
    const forms = document.querySelectorAll('form[data-form-id]:not([data-hubspot-embed-modified])');
    forms.forEach(form => {
        // Setting this attribute to ensure each form is called only once for updation
        form.setAttribute('data-hubspot-embed-modified', 'true');
        const selectElements = form.querySelectorAll('select');
        selectElements.forEach(selectElement => {
            // Check if the select element has an option with the value "Other"
            if (Array.from(selectElement.options).some(option => option.value === 'Other')) {
                // Get the parent fieldset of the select element
                const selectFieldset = selectElement.closest('fieldset');
                // Get the next sibling fieldset
                const nextSiblingFieldset = selectFieldset.nextElementSibling;
                // Check the input element in the next sibling fieldset for a name starting with "other"
                const otherField = nextSiblingFieldset.querySelector('input[name^="other"]');
                if (otherField) {
                    nextSiblingFieldset.classList.add('hidden');

                    // Add change event listener to detect when user changes the select dropdown
                    selectElement.addEventListener('change', () => {
                        const selectedOption = selectElement.options[selectElement.selectedIndex].value;
                        if (selectedOption === 'Other') {
                            nextSiblingFieldset.classList.remove('hidden');
                        } else {
                            nextSiblingFieldset.classList.add('hidden');
                            otherField.value = '';
                        }
                    });

                }
            }
        });
    });
}

// Send contact form info to Hubspot
function initHubspotForms() {
    'use strict';

    var formSelector = 'form[data-guid][data-pid]:not([data-hubspot-initialised])';
    var forms = document.querySelectorAll(formSelector);
    var formUrlBase = 'https://api.hsforms.com/submissions/v3/integration/submit/';
    
    forms.forEach(function (form) {
        var formUrl = formUrlBase + form.dataset.pid + '/' + form.dataset.guid;
        var formValidator = new Bouncer('#' + form.id, {
          disableSubmit: true,
          messages: {
            wrongLength: {
              under: 'Please enter a number that is atleast 5 characters long.',
              over: 'Please enter a number that is at most 12 characters long.',
            },
          },
        });
        var formButtons = form.querySelectorAll('[type=submit]');
        var formPhone = form.querySelector('[data-intl-tel-input]');
        
        if (formPhone) {
          var formIntlTelInput = intlTelInput(formPhone, {
            separateDialCode: true,
            preferredCountries: ['sg'],
          });
        }
        
        form.addEventListener('submit', function (e) {
            
            // Avoid native submission to avoid page reload
            e.preventDefault();
            
            // Ensure form data is valid
            var formIsValid = formValidator.validateAll(form).length === 0;
            
            // Get list of fields with values
            var formFields = Array.from(new FormData(form))
                .map(function(field) {
                  var data = { name: field[0], value: field[1] };
                  // if the field is an intl phone field, add the country code info
                  if (formPhone && data.name == formPhone.name && formIntlTelInput) {
                    data.value = '+' + formIntlTelInput.getSelectedCountryData().dialCode + data.value;
                  }
                  return data;
                })
                .filter(function(field) { return field.name && field.value !== ''; })
                ;
            
            if (formIsValid && formFields.length) {
                // Build payload for HubSpot
                // https://legacydocs.hubspot.com/docs/methods/forms/submit_form
                var formData = {
                    fields: formFields,
                    context: {
                        pageUri: window.location.href,
                        pageName: document.title,
                    },
                };
                // Add hutk cookie value only if it's non-empty (else the Hubspot API throws an error)
                var formCookie = getCookie('hubspotutk').trim();
                if (formCookie) {
                    formData.context.hutk = formCookie;
                }
                
                // Disable submissions
                formButtons.forEach(function (button) {
                    button.disabled = true;
                    button.classList.add('form-loading');
                });
                
                // Hide errors
                form.querySelectorAll('.form-alert').forEach(function (el) {
                  el.parentNode.removeChild(el);
                });
                
                // Submit to HubSpot
                fetch(formUrl, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(formData),
                })
                .then(function (response) {
                    return response.json();
                })
                .then(function (data) {
                    if (data.status === 'error') {
                        throw new Error(data.message);
                    } else if (data.redirectUri) {
                        window.location.href = data.redirectUri;
                    }
                })
                .then(function () {
                    form.reset();
                })
                .catch(function () {
                    var error = document.createElement('div');
                    error.classList.add('form-alert', 'error-message');
                    error.innerText = 'Something went wrong. Please try again.';
                    form.append(error);
                })
                .finally(function () {
                    formButtons.forEach(function (button) {
                        button.disabled = false;
                        button.classList.remove('form-loading');
                    });
                });
            }
        });
        
        form.dataset.hubspotInitialised = true;
    });
    
    enableFormButtons();
}

// Read cookie value by name
function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for(let i = 0; i <ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

function enableFormButtons() {
    var formSelector = 'form[data-guid][data-pid]';
    var formButtons = document.querySelectorAll(formSelector + ' button[disabled]');
    
    formButtons.forEach(function (button) {
        button.disabled = false;
    });
}

// Show a notice when copy email element is clicked
function initCopyEmailElementNotice() {
    document.addEventListener('clipboard-copy', function (event) {
        const notice = event.target.querySelector('[data-copy-notice-show]');
        if (notice) {
            notice.style.display = 'block';
            setTimeout(function () {
                notice.style.display = 'none';
            }, 1000);
        }
    });
}

var isInitialLoad = true;
function main() {
    if (isInitialLoad) {
        initMobileNav();
        initTypeform();
        initVideos();
        initAnalytics();
        initFooterNav();
        initFixedHeaderNav();
        isInitialLoad = false;
    }
    
    initHubspotEmbeds();
    initHubspotForms();
    initLightboxes();
    initCopyEmailElementNotice();
    $(window).trigger('resize');
}

$(main);
